import React, { useState } from "react"
import Layout from "../../components/layout"
import { CpaService } from "../../services/cpa.service"
import { authService } from "../../services/auth.service"
import {
  Form,
  Input,
  Divider,
  Radio,
  Select,
  Upload,
  Checkbox,
  message,
  Modal,
} from "antd"
import { UploadOutlined } from "@ant-design/icons"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"
const { Option } = Select

//form formatting
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function CPAform() {
  // const { user } = useContext(AuthContext)
  // if (!user) navigate("/login")

  ////----file upload logic---
  const { Dragger } = Upload

  const [fileList, setFileList] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(false)
  const [previewTitle, setPreviewTitle] = useState(false)

  const handleCancel = () => setPreviewVisible(false)

  //preview modal
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    )
  }

  const beforeUpload = file => {
    if (!["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
      message.error(`${file.name} is not a valid image type`, 2)
      return null
    }
    return false
  }

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter(file => file.status !== "error"))

  //delete from file list
  const onRemove = async file => {
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)

    setFileList(newFileList)
  }

  const handleFinish = async values => {
    console.log(values)
    try {
      setSubmitting(true)

      values.phone = "+" + values.prefix + values.phone

      delete values["prefix"]
      delete values["upload"]
      delete values["orgUpload"]

      values.createTime = firebase.firestore.Timestamp.now()
      values.status = "Pending"

      values.uid = authService.getUser().uid

      if (indi) {
        values.type = "Individual"
      } else {
        values.type = "Organization"
      }

      Object.keys(values).forEach(
        key => values[key] === undefined && delete values[key]
      )

      let docId = await CpaService.createCpa(values)

      await Promise.all(
        fileList.map(async file => {
          const fileName = `cpa/${Date.now()}-${file.name}`
          const fileRef = firebase.storage().ref().child(fileName)
          try {
            const designFile = await fileRef.put(file.originFileObj)
            const downloadUrl = await designFile.ref.getDownloadURL()

            const item = {
              name: file.name,
              url: downloadUrl,
              path: fileName,
              uploadedAt: firebase.firestore.Timestamp.now(),
            }

            await firebase
              .firestore()
              .collection("/cpa/" + docId + "/file/")
              .add(item)
          } catch (e) {
            console.log(e)
          }
        })
      )

      // clean up -> depart
      setFileList([])
      navigate("/app/account")
      message.success(`Application Submitted Successfully.`, 2)
      // setTimeout(function() {
      //   navigate("/account")
      // }, 2000);
    } catch (err) {
      console.log(err)
      message.error(`Error Submiting Form.`, 2)
    } finally {
      setSubmitting(false)
    }
  }

  const [form] = Form.useForm()

  //true for (Form A - for individuals)
  const [indi, setIndi] = useState(true)
  const changeRole = e => {
    setIndi(e.target.value)
  }

  // const normFile = e => {
  //   // console.log("Upload event:", e)
  //   // console.log(e.fileList)
  //   if (Array.isArray(e)) {
  //     return e
  //   }
  //   return e && e.fileList
  // }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
  }

  //add the country code as antd form addonBefore
  const prefix = (
    <Form.Item name="prefix" noStyle>
      <Input
        prefix="+"
        style={{
          width: 70,
        }}
      />
    </Form.Item>
  )
  return (
    <Layout>
      <div className="mt-7 pt-5 shadow sm:rounded-md overflow-x-hidden">
        <div className="flex justify-center	">
          <Radio.Group onChange={changeRole} value={indi}>
            <Radio.Button value={true}> Individuals</Radio.Button>
            <Radio.Button value={false}> Organizations</Radio.Button>
          </Radio.Group>
        </div>

        {/* <div className="flex justify-center	w-screen"> */}
        <Form
          name="basic"
          autoComplete="off"
          {...layout}
          form={form}
          onFinish={handleFinish}
        >
          <div className="px-4 py-5 bg-white sm:p-6">
            {indi ? (
              <div>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Middle Name" name="middleName">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Nationality"
                  name="nationality"
                  // hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input a nationality!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            ) : (
              <Form.Item
                label="Organization Name"
                name="orgName"
                rules={[
                  {
                    required: true,
                    message: "Please input your organization name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please input a country!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="State/Province"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please input a state!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please input a City!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Street"
              name="street"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Zipcode/Postcode"
              name="zipcode"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* 
            {indi ? null : (
              <Form.Item
                name="orgUpload"
                label="Proof of Status"
                // valuePropName="fileList"
                // getValueFromEvent={normFile}
                extra="We require a copy of the organization’s status "
                rules={[
                  {
                    required: true,
                    message: "Please upload!",
                  },
                ]}
              >
                <Upload name="logo" action="/upload.do" listType="picture">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            )} */}
            {indi ? null : (
              <div className="hidden sm:block" aria-hidden="true">
                <Divider />
              </div>
            )}
            {indi ? null : (
              <div>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Middle Name"
                  name="middleName"
                  // style={{ width: "60%" }}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            )}
            {indi ? null : (
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Not a valid email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone!",
                },
              ]}
            >
              <Input addonBefore={prefix} />
            </Form.Item>

            <div className="hidden sm:block" aria-hidden="true">
              <Divider />
            </div>
            <Form.Item
              label="Identification"
              name="upload"
              //   valuePropName="fileList"
              //   getValueFromEvent={normFile}
              extra={
                (indi
                  ? "We require a picture/scan of a valid government identification. "
                  : "We require a copy of the organization’s status and a picture/scan of a valid government identification. ") +
                "(Si-Yo will never share your personal identity or information with any government agency or marketing/media company.)"
              }
              rules={[
                {
                  required: true,
                  message: "Please upload!",
                },
              ]}
            >
              <Dragger
                listType="picture-card"
                fileList={fileList}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={onRemove}
                multiple={true}
                maxCount={4}
                //   disabled={disableUpload}
              >
                <div className="uploadIcon">
                  <UploadOutlined />
                </div>
                <div className="uploadText">
                  <p>Drag and drop here</p>
                  <p>OR</p>
                  <p>Click</p>
                </div>
              </Dragger>
              {/* <Upload name="logo" action="/upload.do" listType="picture">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload> */}
            </Form.Item>
            <Form.Item
              className="sm:w-screen sm:relative sm:left-60"
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement")),
                },
              ]}
            >
              <Checkbox>
                I have read the{" "}
                <a className="text-blue-700" href="">
                  Content Provider Agreement
                </a>
              </Checkbox>
            </Form.Item>
          </div>

          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              disabled={submitting}
              type="submit"
              className="inline-flex justify-center disabled:opacity-50 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              // onSubmit={handleSubmit}
            >
              {submitting ? "Submitting" : "Submit"}
            </button>
          </div>
        </Form>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      {/* </div> */}
    </Layout>
  )
}

export default CPAform
